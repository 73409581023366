import React, { useState, useEffect, useContext } from "react";
import "../styles/components/UpdateField.css"; // External CSS for better management
import shield from "../images/UpdateField/Shield.png";
import water from "../images/UpdateField/Water Treatment.png";
import town from "../images/UpdateField/Town Hall.png";
import mine from "../images/UpdateField/Mine.png";
import military from "../images/UpdateField/Militry Base.png";
import bg from "../images/UpdateField/Mars Land.png";
import market from "../images/UpdateField/Market.png";
import launch from "../images/UpdateField/Launch Pad.png";
import lab from "../images/UpdateField/laboratory.png";
import hospital from "../images/UpdateField/Hospital.png";
import communication from "../images/UpdateField/Communications.png";



import waterInfo from "../images/UpdateField/InfoUp/Water Treatment 1.png";
import townInfo from "../images/UpdateField/InfoUp/Town Hall 2.png";
import mineInfo from "../images/UpdateField/InfoUp/Mine 1.png";
import militaryInfo from "../images/UpdateField/InfoUp/Militry Base 1.png";
import marketInfo from "../images/UpdateField/InfoUp/Market 1.png";
import launchInfo from "../images/UpdateField/InfoUp/Launch Pad 1.png";
import labInfo from "../images/UpdateField/InfoUp/laboratory 1.png";
import hospitalInfo from "../images/UpdateField/InfoUp/Hospital 1.png";
import communicationInfo from "../images/UpdateField/InfoUp/Communications 1.png";


import { CoinContext } from "../components/CoinContext";
import { Navigate } from "react-router-dom";


const UpdateField = () => {
    const [selectedBuilding, setSelectedBuilding] = useState(null);
    const [buildingLevels, setBuildingLevels] = useState({}); // Store building levels
    const { coinBalance, setCoinBalance, profit, setProfit } = useContext(CoinContext);



    const buildingUpdatesinfo = {
        townHall: {
            "level1": { "cost": 100000, "profit": 10000 },
            "level2": { "cost": 200000, "profit": 20000 },
            "level3": { "cost": 400000, "profit": 30000 },
            "level4": { "cost": 800000, "profit": 40000 },
            "level5": { "cost": 3200000, "profit": 50000 },
            "level6": { "cost": 6400000, "profit": 60000 },
            "level7": { "cost": 12800000, "profit": 70000 },
            "level8": { "cost": 25600000, "profit": 80000 },
            "level9": { "cost": 51200000, "profit": 90000 },
            "level10": { "cost": 100000000, "profit": 100000 },
        },
        shield: {
            "level1": { "cost": 50000, "profit": 5000 },
            "level2": { "cost": 100000, "profit": 10000 },
            "level3": { "cost": 150000, "profit": 10000 },
            "level4": { "cost": 200000, "profit": 10000 },
            "level5": { "cost": 250000, "profit": 10000 },
            "level6": { "cost": 300000, "profit": 10000 },
            "level7": { "cost": 350000, "profit": 10000 },
            "level8": { "cost": 400000, "profit": 10000 },
            "level9": { "cost": 450000, "profit": 10000 },
            "level10": { "cost": 500000, "profit": 10000 },
        },
        waterTreatment: {
            "level1": { "cost": 50000, "profit": 5000 },
            "level2": { "cost": 100000, "profit": 10000 },
            "level3": { "cost": 150000, "profit": 10000 },
            "level4": { "cost": 200000, "profit": 10000 },
            "level5": { "cost": 250000, "profit": 10000 },
            "level6": { "cost": 300000, "profit": 10000 },
            "level7": { "cost": 350000, "profit": 10000 },
            "level8": { "cost": 400000, "profit": 10000 },
            "level9": { "cost": 450000, "profit": 10000 },
            "level10": { "cost": 500000, "profit": 10000 },
        },
        mine: {
            "level1": { "cost": 50000, "profit": 5000 },
            "level2": { "cost": 100000, "profit": 10000 },
            "level3": { "cost": 150000, "profit": 10000 },
            "level4": { "cost": 200000, "profit": 10000 },
            "level5": { "cost": 250000, "profit": 10000 },
            "level6": { "cost": 300000, "profit": 10000 },
            "level7": { "cost": 350000, "profit": 10000 },
            "level8": { "cost": 400000, "profit": 10000 },
            "level9": { "cost": 450000, "profit": 10000 },
            "level10": { "cost": 500000, "profit": 10000 },
        },
        militaryBase: {
            "level1": { "cost": 50000, "profit": 5000 },
            "level2": { "cost": 100000, "profit": 10000 },
            "level3": { "cost": 150000, "profit": 10000 },
            "level4": { "cost": 200000, "profit": 10000 },
            "level5": { "cost": 250000, "profit": 10000 },
            "level6": { "cost": 300000, "profit": 10000 },
            "level7": { "cost": 350000, "profit": 10000 },
            "level8": { "cost": 400000, "profit": 10000 },
            "level9": { "cost": 450000, "profit": 10000 },
            "level10": { "cost": 500000, "profit": 10000 },
        },
        market: {
            "level1": { "cost": 50000, "profit": 5000 },
            "level2": { "cost": 100000, "profit": 10000 },
            "level3": { "cost": 150000, "profit": 10000 },
            "level4": { "cost": 200000, "profit": 10000 },
            "level5": { "cost": 250000, "profit": 10000 },
            "level6": { "cost": 300000, "profit": 10000 },
            "level7": { "cost": 350000, "profit": 10000 },
            "level8": { "cost": 400000, "profit": 10000 },
            "level9": { "cost": 450000, "profit": 10000 },
            "level10": { "cost": 500000, "profit": 10000 },
        },
        launchPad: {
            "level1": { "cost": 50000, "profit": 5000 },
            "level2": { "cost": 100000, "profit": 10000 },
            "level3": { "cost": 150000, "profit": 10000 },
            "level4": { "cost": 200000, "profit": 10000 },
            "level5": { "cost": 250000, "profit": 10000 },
            "level6": { "cost": 300000, "profit": 10000 },
            "level7": { "cost": 350000, "profit": 10000 },
            "level8": { "cost": 400000, "profit": 10000 },
            "level9": { "cost": 450000, "profit": 10000 },
            "level10": { "cost": 500000, "profit": 10000 },
        },
        lab: {
            "level1": { "cost": 50000, "profit": 5000 },
            "level2": { "cost": 100000, "profit": 10000 },
            "level3": { "cost": 150000, "profit": 10000 },
            "level4": { "cost": 200000, "profit": 10000 },
            "level5": { "cost": 250000, "profit": 10000 },
            "level6": { "cost": 300000, "profit": 10000 },
            "level7": { "cost": 350000, "profit": 10000 },
            "level8": { "cost": 400000, "profit": 10000 },
            "level9": { "cost": 450000, "profit": 10000 },
            "level10": { "cost": 500000, "profit": 10000 },
        },
        hospital: {
            "level1": { "cost": 50000, "profit": 5000 },
            "level2": { "cost": 100000, "profit": 10000 },
            "level3": { "cost": 150000, "profit": 10000 },
            "level4": { "cost": 200000, "profit": 10000 },
            "level5": { "cost": 250000, "profit": 10000 },
            "level6": { "cost": 300000, "profit": 10000 },
            "level7": { "cost": 350000, "profit": 10000 },
            "level8": { "cost": 400000, "profit": 10000 },
            "level9": { "cost": 450000, "profit": 10000 },
            "level10": { "cost": 500000, "profit": 10000 },
        },
        communication: {
            "level1": { "cost": 50000, "profit": 5000 },
            "level2": { "cost": 100000, "profit": 10000 },
            "level3": { "cost": 150000, "profit": 10000 },
            "level4": { "cost": 200000, "profit": 10000 },
            "level5": { "cost": 250000, "profit": 10000 },
            "level6": { "cost": 300000, "profit": 10000 },
            "level7": { "cost": 350000, "profit": 10000 },
            "level8": { "cost": 400000, "profit": 10000 },
            "level9": { "cost": 450000, "profit": 10000 },
            "level10": { "cost": 500000, "profit": 10000 },
        },
    };

    const buildingImages = {
        townHall: townInfo,
        shield: shield,
        waterTreatment: waterInfo,
        mine: mineInfo,
        militaryBase: militaryInfo,
        market: marketInfo,
        launchPad: launchInfo,
        lab: labInfo,
        hospital: hospitalInfo,
        communication: communicationInfo,
    };
    const buildingNames = {
        townHall: "Town Hall",
        shield: "Shield",
        waterTreatment: "Water Treatment",
        mine: "Mine",
        militaryBase: "Military Base",
        market: "Market",
        launchPad: "Launch Pad",
        lab: "Laboratory",
        hospital: "Hospital",
        communication: "Communications",
    };
    const fetchBuildingLevels = async () => {
        const buildings = [
            "townHall", "shield", "waterTreatment", "mine", "militaryBase",
            "market", "launchPad", "lab", "hospital", "communication"
        ];

        // Fetch each building's level from Cloud Storage
        buildings.forEach((building) => {
            window.Telegram.WebApp.CloudStorage.getItem(building, (err, value) => {
                if (err) {
                    console.error("Error fetching building level:", err);
                } else {
                    if (value === null) {
                        window.Telegram.WebApp.CloudStorage.setItem(building, "0");
                        setBuildingLevels((prev) => ({ ...prev, [building]: 0 }));
                    } else {
                        setBuildingLevels((prev) => ({ ...prev, [building]: parseInt(value, 10) }));
                    }
                }
            });
        });

        // Fetch coin_balance and profit_balance
        window.Telegram.WebApp.CloudStorage.getItem("coin_balance", (err, value) => {
            if (!err && value !== null) {
                setCoinBalance(parseInt(value, 10));
            }else{
                
            }
        });
        window.Telegram.WebApp.CloudStorage.getItem("profit_balance", (err, value) => {
            if (!err && value !== null) {
                setProfit(parseInt(value, 10));
            }
        });
    };
    useEffect(() => {
        fetchBuildingLevels();
    }, []);


    // Handle building click
    const handleBuildingClick = (buildingName) => {
        if (buildingLevels[buildingName] == 10) {
            return;
        }
        setSelectedBuilding(selectedBuilding === buildingName ? null : buildingName);
    };

    // Close the update info when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest(".building") && selectedBuilding) {
                setSelectedBuilding(null);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [selectedBuilding]);

    const handleUpgrade = () => {
        const currentLevel = buildingLevels[selectedBuilding] || 0;
        const nextLevel = currentLevel + 1;
        const upgradeCost = buildingUpdatesinfo[selectedBuilding]?.[`level${nextLevel}`]?.cost;
        const profitGain = buildingUpdatesinfo[selectedBuilding]?.[`level${nextLevel}`]?.profit;

        if (coinBalance >= upgradeCost) {
            const newCoinBalance = coinBalance - upgradeCost;
            const newProfitBalance = profit + profitGain;

            window.Telegram.WebApp.CloudStorage.setItem("coin_balance", newCoinBalance.toString());
            window.Telegram.WebApp.CloudStorage.setItem("profit_balance", newProfitBalance.toString());
            window.Telegram.WebApp.CloudStorage.setItem(selectedBuilding, nextLevel.toString());

            setCoinBalance(newCoinBalance);
            setProfit(newProfitBalance);
            setBuildingLevels((prev) => ({ ...prev, [selectedBuilding]: nextLevel }));
        }
    };

    const upgradeCost = buildingUpdatesinfo[selectedBuilding]?.[`level${(buildingLevels[selectedBuilding] || 0) + 1}`]?.cost || 0;
    const canUpgrade = coinBalance >= upgradeCost;

    return (
        <div className="update-field-container">
            <div className="UpdateHolder">
                <img className="bgImg" src={bg} alt="background" />

                {/* Town in the middle */}
                <div
                    className="building town-hall"
                    onClick={() => handleBuildingClick("townHall")}
                >
                    <img src={town} alt="Town Hall" />
                    <span className="building-number">
                        {(buildingLevels["townHall"] || 0)}
                    </span>
                </div>

                {/* Other buildings */}
                <div
                    className="building shield"
                    onClick={() => handleBuildingClick("shield")}
                >
                    <img src={shield} alt="Shield" />
                    <span className="building-number">
                        {(buildingLevels["shield"] || 0)}
                    </span>                </div>

                <div
                    className="building water-treatment"
                    onClick={() => handleBuildingClick("waterTreatment")}
                >
                    <img src={water} alt="Water Treatment" />
                    <span className="building-number">
                        {(buildingLevels["waterTreatment"] || 0)}
                    </span>                </div>

                <div
                    className="building mine"
                    onClick={() => handleBuildingClick("mine")}
                >
                    <img src={mine} alt="Mine" />
                    <span className="building-number">
                        {(buildingLevels["mine"] || 0)}
                    </span>                </div>

                <div
                    className="building military-base"
                    onClick={() => handleBuildingClick("militaryBase")}
                >
                    <img src={military} alt="Military Base" />
                    <span className="building-number">
                        {(buildingLevels["militaryBase"] || 0)}
                    </span>                </div>

                <div
                    className="building market"
                    onClick={() => handleBuildingClick("market")}
                >
                    <img src={market} alt="Market" />
                    <span className="building-number">
                        {(buildingLevels["market"] || 0)}
                    </span>                </div>

                <div
                    className="building launch-pad"
                    onClick={() => handleBuildingClick("launchPad")}
                >
                    <img src={launch} alt="Launch Pad" />
                    <span className="building-number">
                        {(buildingLevels["launchPad"] || 0)}
                    </span>                </div>

                <div
                    className="building lab"
                    onClick={() => handleBuildingClick("lab")}
                >
                    <img src={lab} alt="Lab" />
                    <span className="building-number">
                        {(buildingLevels["lab"] || 0)}
                    </span>                </div>

                <div
                    className="building hospital"
                    onClick={() => handleBuildingClick("hospital")}
                >
                    <img src={hospital} alt="Hospital" />
                    <span className="building-number">
                        {(buildingLevels["hospital"] || 0)}
                    </span>                </div>

                <div
                    className="building communication"
                    onClick={() => handleBuildingClick("communication")}
                >
                    <img src={communication} alt="Communications" />
                    <span className="building-number">
                        {(buildingLevels["communication"] || 0)}
                    </span>                </div>
            </div>

            {/* Conditionally render the update info */}
            {selectedBuilding && (
                <div className="updateInfo">
                    <div className="ImageAndLevelName">
                        <span>{selectedBuilding} - Level {buildingLevels[selectedBuilding] + 1}</span>
                        <img src={buildingImages[selectedBuilding]} alt={selectedBuilding} style={{ width: "100px" }} />
                    </div>
                    <div>
                        <span>Income: +{buildingUpdatesinfo[selectedBuilding]?.[`level${(buildingLevels[selectedBuilding] || 0) + 1}`]?.profit || "N/A"}</span>
                    </div>
                    <button onClick={handleUpgrade} disabled={!canUpgrade}>
                        <span>
                            Upgrade to level {(buildingLevels[selectedBuilding] || 0) + 1} - Cost: {upgradeCost}
                        </span>
                    </button>
                </div>
            )}


        </div>
    );
};

export default UpdateField;
