import { CoinProvider } from "../components/CoinContext"
import GoldProfit from "../components/GoldProfit"
import NavbarMine from "../components/NavbarMine"
import UpdateField from "../components/UpdateField"

const Mars = () => {
    return (
        <CoinProvider>
            <GoldProfit />
            <UpdateField />
            <NavbarMine />
        </CoinProvider>
    )
}
export default Mars