import React, { useState, useEffect } from "react";
import "../styles/components/TasksResponse.css";
import Warning from "../images/Tasks/Warning 1.png";
import Coin from "../images/Tasks/Coin (5).png";
import yohoo from "../images/StarsSelect/Happy 1.png";
import sheypour from "../images/StarsSelect/1f389 1.png";
import { useNavigate } from "react-router-dom";

const TasksResponse = ({ selectedTask }) => {
    const navigate = useNavigate();
    const [taskCompleted, setTaskCompleted] = useState(null); // null: not completed, true: completed, false: not completed
    const [prizetext, setPrizetext] = useState(null);

    useEffect(() => {
        if (selectedTask) {
            setPrizetext(selectedTask.prize);
        }
    }, [selectedTask]);

    if (!selectedTask) {
        return null; // Don't render anything if no task is selected
    }

    const { id, prize, type, url } = selectedTask;
    const penalty = prize * 2;

    const handleCompletedClick = async () => {
        if (type === "tel") {
            const baseUrl = "https://t.me/";
            const channelId = "@" + url.substring(baseUrl.length);

            if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.CloudStorage) {
                const tg = window.Telegram.WebApp;
                const userId = tg.initDataUnsafe.user.id; // Get the user ID from Telegram WebApp

                try {
                    const response = await fetch('https://api.spacefi.me/api/check-user-channel', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            userId: userId,
                            channelId: channelId,
                        }),
                    });

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const result = await response.json();
                    console.log('API Response:', result);

                    if (result.is_member) {
                        setTaskCompleted(true); // Mark task as completed
                    } else {
                        setTaskCompleted(false); // Mark task as not completed
                    }

                } catch (error) {
                    console.error('Error:', error);
                    // Handle the error as needed
                }
            }

        } else {
            setTaskCompleted(true);
        }
    };

    const getBackTOHome = () => {
        navigate('/tgbot');
    }

    const GivePrizeToUser = async () => {
        setPrizetext("Wait...");
        const url = 'https://api.spacefi.me/api/Maketask';
        const accessToken = localStorage.getItem('token');
        try {
            // Make the PUT request
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}` // Include the access token
                },
                body: JSON.stringify({ id }) // Pass the ID in the request body
            });

            // Check if the response is OK (status code 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Parse the JSON response
            const data = await response.json();

            // Update the prize text based on the response
            if (data.situation === 'Done') {
                try {
                    // Get the current coin balance
                    const currentCoinValue = await new Promise((resolve, reject) => {
                        window.Telegram.WebApp.CloudStorage.getItem('coin_balance', (err, value) => {
                            if (err) {
                                reject(err);
                            } else {
                                resolve(Number(value) || 0); // Resolve with the current coin value or 0 if not set
                            }
                        });
                    });

                    // Calculate the new coin value
                    const newCoinValue = currentCoinValue + prize;

                    // Set the new coin balance
                    await new Promise((resolve, reject) => {
                        window.Telegram.WebApp.CloudStorage.setItem('coin_balance', String(newCoinValue), (err) => {
                            if (err) {
                                reject(err);
                            } else {
                                resolve();
                                navigate('/tgbot');
                            }
                        });
                    });
                } catch (error) {
                    console.error('Error handling coin balance:', error);
                }

            }

            // Optionally, handle the response data
            console.log(data);

        } catch (error) {
            // Handle any errors that occurred during the fetch
            setPrizetext("Failed to give prize.");
            console.error('Error:', error);
        }
    }

    return (
        <div className="ResponseTaskContainer">
            {taskCompleted === null && (
                <div className="TaskResponseHolder">
                    <div className="TaskResponseHolderContainer">
                        <img src={Warning} alt="Warning" className="WariningImg" />
                        <span className="WarningText">WARNING !</span>
                        <span className="WarningDescText">
                            If we see that you’re cheating you’ll be penalized with <span>TWICE THE COINS</span>
                        </span>

                        <div className="PenaltyAndRewardContainer">
                            <div className="samteChapContainerRP">
                                <div>
                                    <span>{`+${prize.toLocaleString()}`}</span>
                                    <img src={Coin} alt="Coin" />
                                </div>
                                <span>Reward</span>
                            </div>

                            <div className="samteRastContainerRP">
                                <div>
                                    <span>{`-${penalty.toLocaleString()}`}</span>
                                    <img src={Coin} alt="Coin" />
                                </div>
                                <span>Penalty</span>
                            </div>
                        </div>
                        <div className="ButtonsWarning">
                            <button onClick={handleCompletedClick}>{`YES, COMPLETED `}</button>
                            <button onClick={getBackTOHome}>let me check again</button>
                        </div>
                    </div>
                </div>
            )}

            {taskCompleted === true && (
                <div className="WrongSelectStarContainer">
                    <img src={yohoo} alt="Happy" className="sadAstro happy" />
                    <div className="textPartSelectStar">
                        <span className="oopsSelectStar">Yoohoo</span>
                        <div className="Text2SelectStar">
                            <span>Well done captain</span>
                            <img src={sheypour} alt="Celebration" />
                        </div>
                        <div className="ButtontryAgainSelectStar goodJobStar" onClick={GivePrizeToUser}>
                            {prizetext}
                        </div>
                    </div>
                </div>
            )}

            {taskCompleted === false && (
                <div className="TaskResponseHolder">
                    <div className="TaskResponseHolderContainer DidntSub">
                        <img src={Warning} alt="Warning" className="WariningImg" />
                        <span className="WarningText">OOPS !!</span>
                        <span className="WarningDescText DidntSubDesc">
                            Look Like You Didn't Complete The Task!!
                        </span>
                        <div className="ButtonsWarning DidntSubBtn">
                            <div onClick={getBackTOHome}>let me check again</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TasksResponse;
