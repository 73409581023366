import React, { useState, useEffect } from "react";
import "../styles/components/DisplayTasks.css";
import telegram from "../images/Tasks/Telegram 3.png";
import ig from "../images/Tasks/Instagram_logo_2016 copy 1.png";
import yt from "../images/Tasks/Symbol-Youtube 1.png";
import spaceX from "../images/Tasks/X 1.png";
import gold from "../images/Tasks/Coin (4).png";
import TasksResponse from "./TasksResponse";

// Map task types to corresponding icons
const iconMap = {
    ig: ig,
    yt: yt,
    tel: telegram,
    X: spaceX,
};

const DisplayTasks = () => {
    const [tasks, setTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);

    useEffect(() => {
        // Fetch the tasks from the API
        const fetchTasks = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await fetch("https://api.spacefi.me/api/gettask", {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setTasks(data);
            } catch (error) {
                console.error("Error fetching tasks:", error);
            }
        };

        fetchTasks();
    }, []);

    // Handle opening Telegram link
    const handleTelegramClick = (url, task) => {
        window.Telegram.WebApp.openTelegramLink(url);
        setSelectedTask(task); // Set the selected task to display response
    };

    // Handle opening external link
    const handleExternalLinkClick = (url, task) => {
        window.Telegram.WebApp.openLink(url, { try_instant_view: true });
        setSelectedTask(task); // Set the selected task to display response
    };

    return (
        <div>
            <div className="TasksHolder">
                {tasks.map((task) => (
                    <div key={task.id} className="taskHolder">
                        <div className="LeftSideTask">
                            <img src={iconMap[task.type]} alt={task.type} />
                            <div className="LeftSideTaskText">
                                <span className="TextOfLeftSide">{task.text}</span>
                                <div className="PrizeOfLeftSide">
                                    <span>{task.prize.toLocaleString()}</span>
                                    <img src={gold} alt="Prize" />
                                </div>
                            </div>
                        </div>
                        <a
                            href="#"
                            onClick={() =>
                                task.type === "tel"
                                    ? handleTelegramClick(task.url, task)
                                    : handleExternalLinkClick(task.url, task)
                            }
                        >
                            GO
                        </a>
                    </div>
                ))}
            </div>

            {/* Conditionally render TasksResponse if a task is selected */}
            {selectedTask && <TasksResponse selectedTask={selectedTask} />}
        </div>
    );
};

export default DisplayTasks;
