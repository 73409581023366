import Wallet from "../images/FriendsWallet/Wallet 1 (1).png"
import Friends from "../images/FriendsWallet/Add friend 1.png"
import { Link } from "react-router-dom"
import "../styles/components/FriendsWallet.css"
const FriendsWallet =() => {
    return(
        <div className="FriednsWallet">
            <Link to="/tgbot/Friends">
                <img src={Friends} alt="friends"/>
                <span>Frens</span>
            </Link>
            <Link to="/tgbot/Wallet">
                <img src={Wallet} alt="Wallet"/>
                <span>Wallet</span>
            </Link>
        </div>
    )
}
export default FriendsWallet;