import tap from "../images/BoostItems/tap.png";
import fill from "../images/BoostItems/fill.png";
import capacity from "../images/BoostItems/capacity.png";
import astro from "../images/BoostItems/astro.png";
import { CoinContext } from './CoinContext';
import { useContext, useEffect, useState } from "react";
import "../styles/components/UpdateStats.css";

const BoostItems = () => {
    const { coinBalance } = useContext(CoinContext); // Get coinBalance from CoinContext
    const [buildingLevels, setBuildingLevels] = useState({});
    const [coin, setCoin] = useState(0);
    const [isLoading, setIsLoading] = useState(true); // Loading state

    const buildings = ["tap", "capacity", "fillrate", "astro"];

    // Helper function to get a building level from CloudStorage
    const getBuildingLevel = (building) => {
        return new Promise((resolve, reject) => {
            window.Telegram.WebApp.CloudStorage.getItem(building, (err, value) => {
                if (err) {
                    console.error(`Error fetching ${building} level:`, err);
                    reject(err);
                } else {
                    resolve(value !== null ? parseInt(value, 10) : 0); // Default to 0 if value is null
                }
            });
        });
    };

    // Fetch all building levels
    const fetchBuildingLevels = async () => {
        try {
            setIsLoading(true); // Start loading
            const levels = await Promise.all(buildings.map((building) => getBuildingLevel(building)));
            const levelsObject = buildings.reduce((acc, building, index) => {
                acc[building] = levels[index];
                return acc;
            }, {});

            setBuildingLevels(levelsObject); // Update building levels state
        } catch (error) {
            console.error("Error fetching building levels:", error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    // Fetch coin balance and building levels on page load
    useEffect(() => {
        if (coinBalance !== undefined) {
            setCoin(Number(coinBalance));
        }
        fetchBuildingLevels(); // Fetch building levels on component mount
    }, [coinBalance]); // Also re-run this effect if coinBalance changes

    const upgradeLevels = {
        tap: { levels: [10000, 30000, 50000, 70000, 100000, 150000, 200000, 300000, 500000, 1000000] },
        capacity: { levels: [10000, 30000, 50000, 70000, 100000, 150000, 200000, 300000, 500000, 1000000] },
        fillrate: { levels: [100000, 300000, 500000] },
        astro: { levels: [1000000, 3000000, 5000000] }
    };

    const getNextPrice = (building, currentLevel) => {
        const nextLevel = currentLevel + 1;
        return upgradeLevels[building].levels[nextLevel - 1]; // Get the price for the next level
    };

    const getItemClass = (building, currentLevel) => {
        const nextPrice = getNextPrice(building, currentLevel);
        if (currentLevel >= upgradeLevels[building].levels.length) {
            return "MaxLevelItem"; // Max level reached
        }
        return coin >= nextPrice ? "ActiveItemToBuy" : "InactiveItemToBuy"; // Based on the coin balance
    };

    const handleUpgrade = async (building) => {
        const currentLevel = buildingLevels[building] || 0;
        const nextPrice = getNextPrice(building, currentLevel);

        if (coin >= nextPrice) {
            // Proceed with the upgrade
            const newLevel = currentLevel + 1;
            const newCoinBalance = coin - nextPrice;

            console.log(`Upgrading ${building} from level ${currentLevel} to ${newLevel}`);

            try {
                // Update the building level in CloudStorage
                await new Promise((resolve, reject) => {
                    window.Telegram.WebApp.CloudStorage.setItem(building, String(newLevel), (err) => {
                        if (err) {
                            console.error("Error updating building level:", err);
                            reject(err);
                        } else {
                            resolve();
                        }
                    });
                });

                // Update coin balance in CloudStorage
                await new Promise((resolve, reject) => {
                    window.Telegram.WebApp.CloudStorage.setItem("coin_balance", String(newCoinBalance), (err) => {
                        if (err) {
                            console.error("Error updating coin balance:", err);
                            reject(err);
                        } else {
                            resolve();
                        }
                    });
                });

                // Handle specific upgrades
                if (building === "tap") {
                    await new Promise((resolve, reject) => {
                        window.Telegram.WebApp.CloudStorage.getItem('increment_amount', (err, value) => {
                            if (err) {
                                console.error("Error fetching increment_amount:", err);
                                reject(err);
                            } else {
                                const incrementAmount = Number(value) || 0;
                                window.Telegram.WebApp.CloudStorage.setItem('increment_amount', String(incrementAmount + 1), (err) => {
                                    if (err) {
                                        console.error("Error updating increment_amount:", err);
                                        reject(err);
                                    } else {
                                        resolve();
                                    }
                                });
                            }
                        });
                    });
                } else if (building === "capacity") {
                    await new Promise((resolve, reject) => {
                        window.Telegram.WebApp.CloudStorage.getItem('MaxFillbar', (err, value) => {
                            if (err) {
                                console.error("Error fetching MaxFillbar:", err);
                                reject(err);
                            } else {
                                const maxFillbar = Number(value) || 0;
                                window.Telegram.WebApp.CloudStorage.setItem('MaxFillbar', String(maxFillbar + 500), (err) => {
                                    if (err) {
                                        console.error("Error updating MaxFillbar:", err);
                                        reject(err);
                                    } else {
                                        resolve();
                                    }
                                });
                            }
                        });
                    });
                } else if (building === "fillrate") {
                    await new Promise((resolve, reject) => {
                        window.Telegram.WebApp.CloudStorage.getItem('Fillrate', (err, value) => {
                            if (err) {
                                console.error("Error fetching Fillrate:", err);
                                reject(err);
                            } else {
                                const fillRate = Number(value) || 0;
                                window.Telegram.WebApp.CloudStorage.setItem('Fillrate', String(fillRate + 1), (err) => {
                                    if (err) {
                                        console.error("Error updating Fillrate:", err);
                                        reject(err);
                                    } else {
                                        resolve();
                                    }
                                });
                            }
                        });
                    });
                }

                // After updating in cloud storage, update state
                setBuildingLevels((prev) => ({
                    ...prev,
                    [building]: newLevel
                }));

                setCoin(newCoinBalance); // Update coin state

            } catch (error) {
                console.error("Error during upgrade process:", error);
            }
        } else {
            alert("Not enough coins to upgrade!");
        }
    };

    if (isLoading) {
        return <div>Loading...</div>; // Show loading indicator while fetching
    }

    return (
        <div className="BoostingItemsContainer">
            <div className="ItemsForBoost">
                {buildings.map((building, index) => {
                    const currentLevel = buildingLevels[building] || 0;
                    const nextPrice = getNextPrice(building, currentLevel);
                    const itemClass = getItemClass(building, currentLevel);

                    return (
                        <div
                            key={index}
                            className={`ItemBoost ${itemClass}`}
                            onClick={() => {
                                if (itemClass === "ActiveItemToBuy") handleUpgrade(building);
                            }}
                        >
                            <div className="chapboost">
                                <div>
                                    <img src={building === 'tap' ? tap : building === 'capacity' ? capacity : building === 'fillrate' ? fill : astro} alt={building} />
                                </div>
                                <div>
                                    <span>{building.charAt(0).toUpperCase() + building.slice(1)}</span>
                                </div>
                            </div>
                            <span className="itemsLevel">LVL {currentLevel}</span>
                            <div className="rastBoost">
                                {currentLevel >= upgradeLevels[building].levels.length ? "Max Level" : nextPrice}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default BoostItems;
