import React, { useContext, useEffect } from 'react';
import { CoinContext } from './CoinContext';
import "../styles/components/Fillbar.css";

const FillBar = () => {
  const { maxFillBar, localFillBar, setLocalFillBar, FillBar, setFilBar, fillRate } = useContext(CoinContext);

  // Function to save the fillbar value to cloud storage
  const saveBalanceToCloud = (finalBalance) => {
    setFilBar(finalBalance);
    window.Telegram.WebApp.CloudStorage.setItem('FillBar_5', finalBalance.toString(), (setError) => {
      if (setError) {
        console.error('Error setting fillbar balance:', setError);
      }
    });
  };

  // Effect to update the fillbar every second
  useEffect(() => {
    const incrementInterval = setInterval(() => {
      setLocalFillBar((prevFillBar) => {
        const newFillBar = Math.min(prevFillBar + fillRate, maxFillBar);
        return newFillBar;
      });
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(incrementInterval);
  }, [fillRate, maxFillBar, setLocalFillBar]);

  // Effect to save the fillbar value to cloud storage every 10 seconds
  useEffect(() => {
    const saveInterval = setInterval(() => {
      saveBalanceToCloud(localFillBar);
    }, 10000); // Saving every 10 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(saveInterval);
  }, [localFillBar]);

  return (
    <div className='FillBar'>
      {localFillBar}
    </div>
  );
};

export default FillBar;
