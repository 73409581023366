import React, { useState } from "react";
import BackToHomeAsli from "../components/BackToHomeAsli";
import DisplayTasks from "../components/DisplayTasks";
import TasksResponse from "../components/TasksResponse";

const Tasks = () => {
    const [selectedTask, setSelectedTask] = useState(null);

    const handleTaskClick = (task) => {
        setSelectedTask(task);
    };

    return (
        <div>
            <BackToHomeAsli />
            <DisplayTasks onTaskClick={handleTaskClick} />
            <TasksResponse selectedTask={selectedTask} />
        </div>
    );
};

export default Tasks;
