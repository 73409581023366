import React, { useState, useContext, useRef } from 'react';
import { CoinContext } from './CoinContext';
import coin from '../images/coin/SpaceFi 1.png';
import '../styles/components/Coin.css';

const Coin = () => {
  const [transform, setTransform] = useState('');
  const [numbers, setNumbers] = useState([]);
  const { setCoinBalance, localBalance, setLocalBalance, incrementAmount, localFillBar, setLocalFillBar, FillBar, setFilBar } = useContext(CoinContext);
  const saveTimeout = useRef(null);
  const numberSize = 24;
  const lastTouchEvent = useRef(null); // To keep track of the last touch event timestamp

  const saveBalanceToCloud = (finalBalance) => {
    setCoinBalance(finalBalance);
    window.Telegram.WebApp.CloudStorage.setItem('coin_balance', finalBalance.toString(), (setError) => {
      if (setError) {
        console.error('Error setting coin balance:', setError);
      }
    });
  };

  const saveFiillBarBalanceToCloud = (finalBalance) => {
    setFilBar(finalBalance);
    window.Telegram.WebApp.CloudStorage.setItem('FillBar_5', finalBalance.toString(), (setError) => {
      if (setError) {
        console.error('Error setting fillbar balance:', setError);
      }
    });
  };

  const saveLastClickTimestampToCloud = (timestamp) => {
    window.Telegram.WebApp.CloudStorage.setItem('last_click_timestamp', timestamp.toString(), (setError) => {
      if (setError) {
        console.error('Error setting last click timestamp:', setError);
      }
    });
  };

  const handleClick = (offsetX, offsetY, target) => {
    if (localFillBar <= 0) {
      return; // Do nothing if fill bar is 0 or less
    }

    const { offsetWidth, offsetHeight } = target;

    const centerX = offsetWidth / 2;
    const centerY = offsetHeight / 2;

    const rotateX = -(offsetY - centerY) / 10;
    const rotateY = -(centerX - offsetX) / 10;

    setTransform(`rotateX(${rotateX}deg) rotateY(${rotateY}deg)`);

    const id = Math.random().toString(36).substr(2, 9);
    setNumbers((prev) => [
      ...prev,
      {
        id,
        x: offsetX - numberSize / 10,
        y: offsetY - numberSize / 10,
      },
    ]);

    setTimeout(() => {
      setTransform('');
    }, 300);

    setTimeout(() => {
      setNumbers((prev) => prev.filter((num) => num.id !== id));
    }, 1000);

    // Increment local balance and decrement fill bar
    setLocalBalance((prev) => prev + incrementAmount);
    setLocalFillBar((prev) => Math.max(prev - incrementAmount, 0)); // Ensure fill bar does not go below 0

    // Clear the previous timeout and set a new one
    clearTimeout(saveTimeout.current);
    saveTimeout.current = setTimeout(() => {
      // Use the updated localBalance and localFillBar for final save
      saveBalanceToCloud(localBalance + incrementAmount);
      saveFiillBarBalanceToCloud(localFillBar - incrementAmount);
      // Save the current timestamp to cloud storage
      const currentTimestamp = new Date().toISOString();
      saveLastClickTimestampToCloud(currentTimestamp);
    }, 1000);
  };

  const handleTouchStart = (event) => {
    if (localFillBar <= 0) {
      return; // Do nothing if fill bar is 0 or less
    }

    const target = event.currentTarget;
    lastTouchEvent.current = Date.now();
    for (let i = 0; i < event.touches.length; i++) {
      const { clientX, clientY } = event.touches[i];
      const rect = target.getBoundingClientRect();
      const offsetX = clientX - rect.left;
      const offsetY = clientY - rect.top;
      handleClick(offsetX, offsetY, target);
    }
  };

  const handleMouseClick = (event) => {
    if (Date.now() - lastTouchEvent.current < 500) {
      event.preventDefault();
      return;
    }

    if (localFillBar <= 0) {
      return; // Do nothing if fill bar is 0 or less
    }

    handleClick(event.nativeEvent.offsetX, event.nativeEvent.offsetY, event.target);
  };

  return (
    <div className="coin">
      <img
        src={coin}
        alt="SpaceFi"
        style={{ transform }}
        onClick={handleMouseClick}
        onTouchStart={handleTouchStart}
      />
      {numbers.map(({ id, x, y }) => (
        <span key={id} className="number" style={{ left: x + 50, top: y }}>
          {incrementAmount}
        </span>
      ))}
    </div>
  );
};

export default Coin;
