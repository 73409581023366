import React, { useState } from 'react';
import FirstAstro from "../components/FirstAstro";
import NavbarStar from "../components/NavbarStar";
import StarsResponse from "../components/StarsResponse";
import StarsSelect from '../components/StarsSelect';
const Stars = () => {
    const [isRight, setIsRight] = useState(false);
    const [visible, setVisible] = useState(false);

    const showResponse = (right) => {
        setIsRight(right);
        setVisible(true);
    };

    return (
        <div>
            <FirstAstro />
            <StarsSelect showResponse={showResponse} />
            {visible && <StarsResponse isRight={isRight} setVisible={setVisible} />}
            <NavbarStar />
        </div>
    );
};

export default Stars;
