import "../styles/components/NavbarStar.css"
import BackgroundActive from "../images/NavbarStar/Group 1 (1).png"
import Star from "../images/NavbarStar/Star.png";
import { Link } from "react-router-dom"
const NavbarStar = () => {
    return (
        <nav>
            <Link to="/tgbot/">Earn</Link>
            <Link to="/tgbot/SelectPlanet">Mine</Link>
            <div>
                <img src={Star} className="goldImgNav" alt="coin" />
                <img className="bgImageNav" src={BackgroundActive} alt="bg" />
                <span>Stars</span>
            </div>


        </nav>
    )
}
export default NavbarStar