import React, { useEffect } from 'react';
import '../App.css';
import "../styles/page/Home.css";
import { Helmet } from 'react-helmet';
import LevelCounting from "../components/LevelCounting";
import GoldProfit from "../components/GoldProfit";
import FriendsWallet from "../components/FriendsWallet";
import Coin from "../components/Coin";
import TaskBoost from "../components/TaskBoost";
import Navbar from "../components/Navbar";
import { CoinProvider } from '../components/CoinContext';
import FillBar from '../components/FillBar';
function Home() {
  useEffect(() => {
    // Check if Telegram Web App API is available
    if (window.Telegram && window.Telegram.WebApp) {
      // Notify Telegram that the Mini App is ready to be displayed
      window.Telegram.WebApp.ready();

      // Expand the Mini App to full height
      window.Telegram.WebApp.expand();
    }
  }, []);

  return (
    <CoinProvider>
      <div>
        <Helmet>
          <script
            src="https://telegram.org/js/telegram-web-app.js"
            type="text/javascript"
            onLoad={() => { }}
          ></script>
        </Helmet>
        <LevelCounting />
        <GoldProfit />
        <FriendsWallet />
        <Coin />
        <FillBar />
        <TaskBoost />
        <Navbar />
      </div>
    </CoinProvider>
  );
}

export default Home;
