import { Link } from "react-router-dom";
import NavbarMine from "../components/NavbarMine";
import Mars from "../images/SelectPlanet/Mars 1.png"
import "../styles/page/SelectPlanet.css"
const SelectPlanet = () => {
    return(
        <div className="PlanetContainer">
            <div className="PlanetHolder">
                <h2>SELECT PLANET</h2>
                <Link to="/tgbot/Mars">
                    <img src={Mars} alt="Mars" />
                    <span>MARS</span>
                </Link>
            </div>
            <NavbarMine />
        </div>
    );
}
export default SelectPlanet;