import "../styles/components/Navbar.css"
import BackgroundActive from "../images/Navbar/Group 1.png"
import gold from "../images/Navbar/Coin.png"
import { Link } from "react-router-dom"
const Navbar = () => {
    return (
        <nav>
            <div>
                <img src={gold} className="goldImgNav" alt="coin" />
                <img className="bgImageNav" src={BackgroundActive} alt="bg" />
                <span>EARN</span>
            </div>
            <Link to="/tgbot/SelectPlanet">MINE</Link>
            <Link to="/tgbot/Stars">STARS</Link>
        </nav>
    )
}
export default Navbar