import React, { useState, useEffect } from 'react';
import GoldImgOff from '../images/global/Coin (1).png';
import profitAStro from '../images/global/Money 1.png';
import '../styles/page/Profit.css';
import { useNavigate } from 'react-router-dom';

// Utility function to format numbers
const formatNumber = (number) => {
  if (number >= 1000000) {
    return `${(number / 1000000).toFixed(2)}M`;
  } else if (number >= 1000) {
    return `${(number / 1000).toFixed(1)}K`;
  } else {
    return number.toFixed(1).toString();
  }
};

const GiveProfit = (props) => {
  const [prize, setPrize] = useState(0); // State to store Prize value
  const navigate = useNavigate();

  // Function to calculate the Prize based on `astro` value
  const calculatePrize = async () => {
    try {
      const astro = await new Promise((resolve, reject) => {
        window.Telegram.WebApp.CloudStorage.getItem('astro', (err, value) => {
          if (err) {
            reject(err);
          } else {
            resolve(Number(value));
          }
        });
      });

      let calculatedPrize = 0;
      if (astro === 0) {
        calculatedPrize = (props.profit / 10800) * props.remainingSec;
      } else if (astro === 1) {
        calculatedPrize = (props.profit / 14400) * props.remainingSec;
      } else if (astro === 2) {
        calculatedPrize = (props.profit / 18000) * props.remainingSec;
      } else if (astro === 3) {
        calculatedPrize = (props.profit / 21600) * props.remainingSec;
      }

      setPrize(calculatedPrize); // Set the prize in state
    } catch (error) {
      console.log("Error calculating prize:", error);
    }
  };

  useEffect(() => {
    // Call the prize calculation when the component loads
    calculatePrize();
  }, []); // Empty dependency array to run this only on mount

  // Function to handle claiming the profit and updating CloudStorage
  const checkAndSetProfit = async () => {
    try {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.CloudStorage) {
        const coinValue = await new Promise((resolve, reject) => {
          window.Telegram.WebApp.CloudStorage.getItem('coin_balance', (err, value) => {
            if (err) {
              reject(err);
            } else {
              resolve(Number(value));
            }
          });
        });

        let newCoinBalance = coinValue + prize;

        // Store the new coin balance in CloudStorage
        await new Promise((resolve, reject) => {
          window.Telegram.WebApp.CloudStorage.setItem('coin_balance', newCoinBalance.toString(), (err) => {
            if (err) {
              reject(err);
            } else {
              window.Telegram.WebApp.CloudStorage.setItem('last_click_timestamp', new Date().toISOString(), (err) => {
                if (err) {
                  console.log(err);
                }
              });
              resolve();
            }
          });
        });

        // Update FillBar logic (as per your current logic)
        const fillBar = await new Promise((resolve, reject) => {
          window.Telegram.WebApp.CloudStorage.getItem('FillBar_5', (err, value) => {
            if (err) {
              reject(err);
            } else {
              resolve(Number(value));
            }
          });
        });

        const maxFillBar = await new Promise((resolve, reject) => {
          window.Telegram.WebApp.CloudStorage.getItem('MaxFillbar', (err, value) => {
            if (err) {
              reject(err);
            } else {
              resolve(Number(value));
            }
          });
        });

        const fillRate = await new Promise((resolve, reject) => {
          window.Telegram.WebApp.CloudStorage.getItem('Fillrate', (err, value) => {
            if (err) {
              reject(err);
            } else {
              resolve(Number(value));
            }
          });
        });

        let newFillBar = (props.remainingSec * fillRate) + fillBar;

        if (newFillBar > maxFillBar) {
          newFillBar = maxFillBar;
        }

        await new Promise((resolve, reject) => {
          window.Telegram.WebApp.CloudStorage.setItem('FillBar_5', newFillBar.toString(), (err) => {
            if (err) {
              reject(err);
            } else {
              props.onScriptLoad(); // Set scriptLoaded to true and handle the transition

            }
          });
        });

      } else {
        navigate('/tgbot/ServerErr');
      }
    } catch (error) {
      navigate('/tgbot/ServerErr');
    }
  };

  return (
    <div className="MainDivProfit">
      <div className="ContainerBox">
        <img src={profitAStro} alt="SpaceFi" className="AstroImgOff" />
        <div className="InfoBox">
          <div className="TextBox">
            <span>Welcome Back Captain</span>
            <span>^_^</span>
          </div>
          <span className="WorkHardBox">
            I was working hard while you were offline
          </span>
          <span className="GoldAmountOff">
            <span>{formatNumber(prize)}</span> {/* Displaying the calculated prize */}
            <img src={GoldImgOff} alt="SpaceFi" />
          </span>
          <button onClick={checkAndSetProfit}>Claim</button>
        </div>
      </div>
    </div>
  );
};

export default GiveProfit;
