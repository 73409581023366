import React from 'react';
import "../styles/components/LinkToInviteFriens.css"
import Bgbtn from "../images/InviteFriendBtn/Group 1 (3).png"
const InviteBtn = () => {
    const message = "https://t.me/spacefi_bot/SpaceFi?startapp=ref_";
    const tg = window.Telegram.WebApp;
    const userId = tg.initDataUnsafe.user.id;
    const fullMessage = message + userId
    // URL encode the message
    const encodedMessage = encodeURIComponent(fullMessage);
    // Construct the Telegram deep link
    const telegramLink = `https://t.me/share/url?url=${encodedMessage}`;

    return (
        <div className='LinkToInviteFriens'>
            <img src={Bgbtn} alt="SpaceFi" />
            <a href={telegramLink} target="_blank" rel="noopener noreferrer" >
                Invite Friend
            </a>
        </div>

    );
};

export default InviteBtn;
