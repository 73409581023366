import React, { useContext } from 'react';
import { CoinContext } from './CoinContext';
import "../styles/components/GoldProfit.css";

const GoldProfit = () => {
  
  const { localBalance, profit } = useContext(CoinContext);

  const formatNumber = (number, isProfit = false) => {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(2) + "M";
    } else if (number >= 1000) {
      return isProfit ? number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : number.toLocaleString(undefined, { maximumFractionDigits: 0 });
    } else {
      return isProfit ? number.toFixed(2) : number.toString();
    }
  };

  return (
    <div className="GoldProfit">
      <span>{formatNumber(localBalance)}</span>
      <span>+{formatNumber(profit, true)}/H</span>
    </div>
  );
};

export default GoldProfit;
