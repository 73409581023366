import React, { useState, useEffect } from 'react';
import three from "../images/ShowFriends/3 Frens Img.png";
import ten from "../images/ShowFriends/10 Frens Img.png";
import hundred from "../images/ShowFriends/100 Frens Img.png";
import thousand from "../images/ShowFriends/1000 Frens Img.png";
import gold from "../images/ShowFriends/Coin (6).png";
import backgroundBtn from "../images/ShowFriends/Group 1 (4).png";
import fillingPurple from "../images/ShowFriends/Rectangle 15.png";
import "../styles/components/ShowFriends.css";

const ShowFrens = () => {
    const [frensCount, setFrensCount] = useState(0);
    const [frens, setFrens] = useState([]);
    const [claimedRewards, setClaimedRewards] = useState({
        three: false,
        ten: false,
        hundred: false,
        thousand: false
    });

    useEffect(() => {
        const fetchFrensData = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch('https://api.spacefi.me/api/showFrens', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.haveFrens === 'yes') {
                        setFrensCount(data.friendCount);
                        setFrens(data.data);
                        setClaimedRewards({
                            three: data.three === 1,
                            ten: data.ten === 1,
                            hundred: data.hundred === 1,
                            thousand: data.Thosound === 1
                        });
                    }
                } else {
                    console.error('Failed to fetch data.');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchFrensData();
    }, []);

    const calculateWidth = (needed) => {
        return `${Math.min((frensCount / needed) * 100, 100)}%`;
    };

    const handleClaim = async (rewardKey, rewardAmount, rewardNumber) => {
        if (claimedRewards[rewardKey]) {
            alert("Reward already claimed!");
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://api.spacefi.me/api/getRewards', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ number: rewardNumber })
            });

            const data = await response.json();

            if (data.situation === 'Done') {
                // Get the current coin balance from Cloud Storage
                const currentCoinValue = await new Promise((resolve, reject) => {
                    window.Telegram.WebApp.CloudStorage.getItem('coin_balance', (err, value) => {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(Number(value) || 0);
                        }
                    });
                });

                // Calculate the new coin value
                const newCoinValue = currentCoinValue + data.Coin;

                // Set the new coin balance in Cloud Storage
                await new Promise((resolve, reject) => {
                    window.Telegram.WebApp.CloudStorage.setItem('coin_balance', String(newCoinValue), (err) => {
                        if (err) {
                            reject(err);
                        } else {
                            resolve();
                        }
                    });
                });

                // Update the local state to reflect the claim
                setClaimedRewards(prev => ({ ...prev, [rewardKey]: true }));

            } else {
                alert("Failed to claim the reward.");
            }
        } catch (error) {
            console.error('Error claiming reward:', error);
            alert("Failed to claim the reward. Please try again.");
        }
    };

    const rewards = [
        { img: three, required: 3, reward: "500,000", amount: 500000, key: 'three', number: 3 },
        { img: ten, required: 10, reward: "2,000,000", amount: 2000000, key: 'ten', number: 10 },
        { img: hundred, required: 100, reward: "50,000,000", amount: 50000000, key: 'hundred', number: 100 },
        { img: thousand, required: 1000, reward: "200,000,000", amount: 200000000, key: 'thousand', number: 1000 },
    ];

    return (
        <div>
            <div className="ShowRewards">
                <h1>Rewards</h1>
                <div className="RewardsContainer">
                    {rewards.map((reward, index) => (
                        <div className="Reward" key={index}>
                            <div className="TopPart">
                                <div className="chap">
                                    <img src={reward.img} alt="SpaceFi" className="tedadImg" />
                                    <div className="textChapRewardInvite">
                                        <span className="Topic">Invite {reward.required} Frens</span>
                                        <div className="ExtraInfoInv">
                                            <img src={gold} alt="SpaceFi" />
                                            <span>{reward.reward}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="buttonReward">
                                    {frensCount >= reward.required && !claimedRewards[reward.key] && (
                                        <img src={backgroundBtn} alt="SpaceFi" />
                                    )}
                                    <button
                                        disabled={frensCount < reward.required || claimedRewards[reward.key]}
                                        onClick={() => handleClaim(reward.key, reward.amount, reward.number)}
                                    >
                                        {claimedRewards[reward.key] ? "Claimed" : "Claim"}
                                    </button>
                                </div>
                            </div>
                            <div className="BottomPart">
                                <div className="fillBar">
                                    <img src={fillingPurple} alt="SpaceFi" style={{ width: calculateWidth(reward.required) }} />
                                </div>
                                <div className="underline"></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <h1 className="FriendsCount">Your Frens({frensCount})</h1>
                <div className="FriendsName">
                    {frens.map((fren, index) => (
                        <div className="Frens" key={index}>
                            <span>{fren}</span>
                            <div className="underline"></div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ShowFrens;
