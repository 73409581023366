import { Link } from "react-router-dom"
import BackBtn from "../images/global/Polygon 1.png"
import "../styles/components/BackToHome.css"
import AstroWallet from "../images/AstroWallet/Bubble Gum 1.png"
const BackToHome = () => {
    return (
        <div className="walletPage">
            <div className="BtnBack">
                <Link to="/tgbot">
                    <img src={BackBtn} alt="Home" />
                </Link>
            </div>
            <div className="AstroWallet">
                <img src={AstroWallet} alt="Astro" />
                <span>
                    COMING 
                </span>
                <span>
                    SOON
                </span>
            </div>
        </div>

    )
}
export default BackToHome 