import React, { useState, useEffect } from 'react';
import star from "../images/StarsSelect/Star 6.png";
import selectedStars from "../images/StarsSelect/Star 17.png";
import coin from "../images/StarsSelect/Coin (3).png";
import "../styles/components/StarsSelect.css";
import axios from 'axios';

const StarsSelect = ({ showResponse }) => {
    const starValues = Array.from({ length: 29 }, (_, index) => index + 1);
    const [selectedStarsState, setSelectedStarsState] = useState([]);
    const [remainingTime, setRemainingTime] = useState(null);
    const [textBtnClick, setTextBtnClick] = useState("Verify");
    const handleStarClick = (index) => {
        setSelectedStarsState(prevState => {
            const selectedValue = starValues[index];
            if (prevState.includes(selectedValue)) {
                return prevState.filter(value => value !== selectedValue);
            } else if (prevState.length < 3) {
                return [...prevState, selectedValue];
            }
            return prevState;
        });
    };

    const isStarSelected = (index) => selectedStarsState.includes(starValues[index]);

    useEffect(() => {
        const winTime = localStorage.getItem('winTime');
        if (winTime == "null") {
            setRemainingTime(null)
        } else {
            const winTimeDate = new Date(winTime);
            const nowUTC = new Date().toISOString().split('T')[0];
            const nowDateUTC = new Date(`${nowUTC}T00:00:00Z`);

            if (winTimeDate.toISOString().split('T')[0] === nowUTC) {
                const nextUTCDate = new Date(nowDateUTC.getTime() + 24 * 60 * 60 * 1000); // Next UTC midnight
                setRemainingTime(Math.max(nextUTCDate - new Date(), 0));
            }
        }



    }, []);

    useEffect(() => {
        let timer;
        if (remainingTime !== null) {
            timer = setInterval(() => {
                setRemainingTime(prevTime => prevTime > 1000 ? prevTime - 1000 : 0);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [remainingTime]);

    const formatTime = (time) => {
        const hours = Math.floor(time / (1000 * 60 * 60));
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((time % (1000 * 60)) / 1000);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const renderButton = () => {
        const winTime = localStorage.getItem('winTime');

        if (winTime == null || remainingTime === null) {
            if (selectedStarsState.length === 0) {
                return (
                    <div className="buttonToSubmitStars">
                        <span className="selectText">Select 3 stars</span>
                        <div>
                            <span>Win 5,000,000</span>
                            <img src={coin} alt="Coin" />
                        </div>
                    </div>
                );
            } else if (selectedStarsState.length === 3) {
                return (
                    <div className="buttonToSubmitStars VerifyThreeSelect SelectedAll" onClick={CheckStars}>
                        {textBtnClick}
                    </div>
                );
            } else {
                return (
                    <div className="buttonToSubmitStars VerifyThreeSelect">
                        Verify
                    </div>
                );
            }
        } else if (remainingTime > 0) {
            return (
                <div className="buttonToSubmitStars VerifyThreeSelect RemainingDiv">
                    {formatTime(remainingTime)}
                </div>
            );
        } else {
            return (
                <div className="buttonToSubmitStars">
                    <span className="selectText">Select 3 stars</span>
                    <div>
                        <span>Win 5,000,000</span>
                        <img src={coin} alt="Coin" />
                    </div>
                </div>
            );
        }
    };

    const CheckStars = async () => {
        setTextBtnClick("waiting...")
        if (selectedStarsState.length !== 3) return; // Ensure 3 stars are selected before proceeding

        const accessToken = localStorage.getItem('token'); // Retrieve the access token from localStorage

        if (!accessToken) {
            console.error('No access token found');
            return;
        }

        try {
            const response = await axios.post(
                'https://api.spacefi.me/api/checkWinStars',
                {
                    one: selectedStarsState[0],
                    two: selectedStarsState[1],
                    three: selectedStarsState[2]
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`, // Include the access token in the request headers
                    }
                }
            );
            if (response.data.situation === "lose") {
                setTextBtnClick("Verify");
                showResponse(false); // Show lose response
            } else {
                try {
                    // Get the current coin balance
                    const currentCoinValue = await new Promise((resolve, reject) => {
                        window.Telegram.WebApp.CloudStorage.getItem('coin_balance', (err, value) => {
                            if (err) {
                                reject(err);
                            } else {
                                resolve(Number(value) || 0); // Resolve with the current coin value or 0 if not set
                            }
                        });
                    });

                    // Calculate the new coin value
                    const newCoinValue = currentCoinValue + 5000000;

                    // Set the new coin balance
                    await new Promise((resolve, reject) => {
                        window.Telegram.WebApp.CloudStorage.setItem('coin_balance', String(newCoinValue), (err) => {
                            if (err) {
                                reject(err);
                            } else {
                                resolve();
                            }
                        });
                    });

                    // Update the UI
                    setTextBtnClick("Verify");
                    showResponse(true); // Show win response

                } catch (error) {
                    console.error('Error handling coin balance:', error);
                }
            }
            console.log('Registration Successful:', response.data);
        } catch (error) {
            console.error('Registration Error:', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div className='MaincontainerSelectStars'>
            <div className="starsContainer">
                {Array.from({ length: 29 }).map((_, index) => (
                    <img
                        key={index}
                        src={isStarSelected(index) ? selectedStars : star}
                        alt={`Star ${starValues[index]}`}
                        className={`stars star-${(index + 1).toString().padStart(2, '0')}`}
                        onClick={() => handleStarClick(index)}
                    />
                ))}
            </div>
            {renderButton()}

        </div>
    );
};

export default StarsSelect;
