import React, { createContext, useState, useEffect } from 'react';

export const CoinContext = createContext();

export const CoinProvider = ({ children }) => {
  const [coinBalance, setCoinBalance] = useState(0);
  const [profit, setProfit] = useState(0);

  const [FillBar, setFilBar] = useState(0);
  const [maxFillBar, setMaxFillBar] = useState(5000); // Default maxFillBar to 5000
  const [localFillBar, setLocalFillBar] = useState(0); // Initialize with 0

  const [storageReady, setStorageReady] = useState(false);
  const [localBalance, setLocalBalance] = useState(0);
  const [incrementAmount, setIncrementAmount] = useState(1); // Default increment amount is 1
  const [fillRate, setFillRate] = useState(2); // Default fill rate is 2

  useEffect(() => {
    const checkStorage = setInterval(() => {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.CloudStorage) {
        setStorageReady(true);
        clearInterval(checkStorage);
      }
    }, 100);

    return () => clearInterval(checkStorage);
  }, []);

  useEffect(() => {
    if (storageReady) {
      // Fetch initial coin balance, profit, and increment amount
      window.Telegram.WebApp.CloudStorage.getItem('coin_balance', (err, value) => {
        if (!err) {
          const balance = parseFloat(value) || 0;
          setCoinBalance(balance);
          setLocalBalance(balance); // Initialize local balance
        }
      });

      window.Telegram.WebApp.CloudStorage.getItem('profit_balance', (err, value) => {
        if (!err) {
          setProfit(parseFloat(value) || 0);
        }
      });

      window.Telegram.WebApp.CloudStorage.getItem('increment_amount', (err, value) => {
        if (!err) {
          setIncrementAmount(parseFloat(value) || 1); // Default to 1 if not set
        }
      });

      window.Telegram.WebApp.CloudStorage.getItem('FillBar_5', (err, value) => {
        if (!err) {
          const balance = parseFloat(value);
          setFilBar(!isNaN(balance) ? balance : 0); // Set default to 0 if NaN
          setLocalFillBar(!isNaN(balance) ? balance : 0); // Initialize localFillBar to 0 if NaN
        }
      });

      window.Telegram.WebApp.CloudStorage.getItem('MaxFillbar', (err, value) => {
        if (!err) {
          const maxFill = parseFloat(value) || 5000; // Default maxFillBar to 5000
          setMaxFillBar(maxFill);
        }
      });

      window.Telegram.WebApp.CloudStorage.getItem('Fillrate', (err, value) => {
        if (!err) {
          setFillRate(parseFloat(value) || 2); // Default fillRate to 2
        }
      });
    }
  }, [storageReady]);

  const saveIncrementAmount = (amount) => {
    window.Telegram.WebApp.CloudStorage.setItem('increment_amount', amount.toString(), (setError) => {
      if (setError) {
        console.error('Error setting increment amount:', setError);
      }
    });
  };

  return (
    <CoinContext.Provider value={{
      coinBalance, setCoinBalance, profit, setProfit, localBalance, setLocalBalance,
      incrementAmount, setIncrementAmount, saveIncrementAmount, maxFillBar, setMaxFillBar,
      localFillBar, setLocalFillBar, FillBar, setFilBar, fillRate, setFillRate
    }}>
      {children}
    </CoinContext.Provider>
  );
};
