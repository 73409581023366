import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './pages/Home';
import Wallet from './pages/Wallet';
import Friends from './pages/Friends';
import Tasks from './pages/Tasks';
import Boost from './pages/Boost';
import LoadingPage from './pages/LoadingPage';
import ServerError from './pages/ServerError';
import GiveProfit from './pages/GiveProfit';
import Stars from './pages/Stars';
import Mine from './pages/Mine';
import SelectPlanet from './pages/SelectPlanet';
import Mars from './pages/Mars';
import FillBar from './components/FillBar';

function App() {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkTelegramReady = setInterval(() => {
      if (window.Telegram && window.Telegram.WebApp) {
        clearInterval(checkTelegramReady);
      }
    }, 100);

    // Check if the device is mobile
    setIsMobile(isMobileDevice());

    return () => clearInterval(checkTelegramReady);
  }, []);

  if (!isMobile) {
    return <div>Your device is not supported.</div>;
  }

  return (
    <Router>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
        <script
          src="https://telegram.org/js/telegram-web-app.js"
          type="text/javascript"
          onLoad={() => setScriptLoaded(true)}
        ></script>
      </Helmet>
      {scriptLoaded ? (
        <Routes>
          <Route path="/tgbot/" element={<Home />} />
          <Route path="/tgbot/Loading" element={<LoadingPage />} />
          <Route path="/tgbot/ServerErr" element={<ServerError />} />
          <Route path="/tgbot/GiveProfit" element={<GiveProfit />} />
          <Route path="/tgbot/Wallet" element={<Wallet />} />
          <Route path="/tgbot/Friends" element={<Friends />} />
          <Route path="/tgbot/Tasks" element={<Tasks />} />
          <Route path="/tgbot/Boost" element={<Boost />} />
          <Route path="/tgbot/Mine" element={<Mine />} />
          <Route path="/tgbot/Stars" element={<Stars />} />
          <Route path="/tgbot/SelectPlanet" element={<SelectPlanet />} />
          <Route path="/tgbot/Mars" element={<Mars />} />
        </Routes>
      ) : (
        <LoadingPage onScriptLoad={() => setScriptLoaded(true)} />
      )}
    </Router>
  );
}

const isMobileDevice = () => {
  return /mobile|android|touch|iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());
};

export default App;
