import { useEffect, useState } from "react";
import gold from "../images/FirstAstro/Coin (2).png"
import "../styles/components/FirstAstro.css"
import bg from "../images/FirstAstro/Group 1 (2).png"
const FirstAstro = () => {
    const [name, setName] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetch('https://api.spacefi.me/api/FirstAstro')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log(data.name)
                setName(data.name);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }, 1000);

    return (
        <div className="FirstAstro">
            {name !== "nobody" ? (
                <div className="bgDivFirstAstro">
                    <img src={bg} alt="SpaceFi" />
                    <div>
                        <span className="AstroName">{name}</span>
                        <span className="Textundername">First Astronaut</span>
                    </div>
                    <div>
                        <span>Won</span>
                        <span className="moneyWonAstro">
                            <span>50M</span>
                            <img src={gold} alt="token" />
                        </span>
                    </div>

                </div>
            ) : (
                <div className="bgDivFirstAstro">
                    <img src={bg} alt="SpaceFi" />

                    <p>
                        BE THE FIRST ASTRONAUT AND CLAIM 50M
                    </p>
                </div>
            )}
        </div>

    );
}
export default FirstAstro;