import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WelcomeImg from '../images/global/Welcome Page 1.jpg';
import '../styles/page/Loading.css';
import GiveProfit from './GiveProfit';
import Home from './Home';
import ServerError from "./ServerError";
import axios from "axios";

const LoadingPage = ({ onScriptLoad }) => {
  const navigate = useNavigate();
  const [profitUser, setProfitUser] = useState();
  const [hasValue, setHasValue] = useState(false);
  const [loading, setLoading] = useState(true);
  const [lastOffline, setLastOffline] = useState(null);

  useEffect(() => {
    const checkAndSetProfit = async () => {
      try {
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.CloudStorage) {
          window.Telegram.WebApp.ready();
          window.Telegram.WebApp.expand();

          const profitValue = await new Promise((resolve, reject) => {
            window.Telegram.WebApp.CloudStorage.getItem('profit_balance', (err, value) => {
              if (err) {
                reject(err);
              } else {
                resolve(Number(value));
              }
            });
          });

          const LastClickValue = await new Promise((resolve, reject) => {
            window.Telegram.WebApp.CloudStorage.getItem('last_click_timestamp', (err, value) => {
              if (err) {
                reject(err);
              } else {
                resolve(value);
              }
            });
          });

          if (LastClickValue !== "") {
            if (profitValue === 0) {
              onScriptLoad();
            } else {
              const now = new Date();
              const timeDiff = now - new Date(LastClickValue); // Ensure LastClickValue is a Date object
              const secondsElapsed = Math.floor(timeDiff / 1000);
              if (secondsElapsed >= 10800) {
                setLastOffline(10800);
              } else if (secondsElapsed === 0) {
                setLastOffline(0);
              } else if (secondsElapsed > 0 && secondsElapsed < 10800) {
                setLastOffline(secondsElapsed);
              }
              setProfitUser(profitValue);
              setHasValue(true);
            }
          } else {
            onScriptLoad();
            setHasValue(false);
          }
        } else {
          navigate('/tgbot/ServerErr');
        }
      } catch (error) {
        navigate('/tgbot/ServerErr');
      } finally {
        setLoading(false);
      }
    };

    const loginAndRegisterUser = async () => {
      if (window.Telegram.WebApp) {
        const tg = window.Telegram.WebApp;
        const id = tg.initDataUnsafe.user.id;
        const firstName = tg.initDataUnsafe.user.first_name;
        const lastName = tg.initDataUnsafe.user.last_name;
        const fullName = `${firstName} ${lastName}`;

        try {
          const response = await axios.post('https://api.spacefi.me/api/login', {
            telid: id, // Sending the telid value in the POST request body
          });

          if (response.data.hasAcc === "no") {
            await registerUser(id, fullName);
          } else if (response.data.hasAcc === "yes") {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('winTime', response.data.user.winTime)

          }

        } catch (error) {
          console.error('Error:', error.response ? error.response.data : error.message);
          navigate('/tgbot/ServerErr');
        }
      }
    };

    const registerUser = async (telid, username) => {
      try {
        const response = await axios.post('https://api.spacefi.me/api/register', {
          telid: telid, // Sending the telid value in the POST request body
          name: username // Sending the username value in the POST request body
        });
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('winTime', response.data.user.winTime)
        const url = window.location.href;
        if (url.includes('ref_')) {
          // Extract telid from the URL
          const telidMatch = url.match(/ref_(\d+)/);
          const telid = telidMatch ? telidMatch[1] : null;

          if (telid) {
            // Step 2: Retrieve token from local storage

            if (response.data.token) {
              // Step 3: Send a PUT request to the API
              fetch('https://api.spacefi.me/api/AddFriend', {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${response.data.token}`, // Include the token if required
                },
                body: JSON.stringify({ telid }),
              })
                .then(response => response.json())
                .then(data => {
                  console.log('Friend added successfully:', data);
                })
                .catch(error => {
                  console.error('Error adding friend:', error);
                });
            }
          }
        }

        await new Promise((resolve, reject) => {
          window.Telegram.WebApp.CloudStorage.setItem('FillBar_5', "5000", (err) => {
            if (err) {
              reject(err);
            }
          });
        });

        await new Promise((resolve, reject) => {
          window.Telegram.WebApp.CloudStorage.setItem('increment_amount', "1", (err) => {
            if (err) {
              reject(err);
            }
          });
        });
        await new Promise((resolve, reject) => {
          window.Telegram.WebApp.CloudStorage.setItem('Fillrate', "1", (err) => {
            if (err) {
              reject(err);
            }
          });
        });


        await new Promise((resolve, reject) => {
          window.Telegram.WebApp.CloudStorage.setItem('MaxFillbar', "5000", (err) => {
            if (err) {
              reject(err);
            }
          });
        });

        await new Promise((resolve, reject) => {
          window.Telegram.WebApp.CloudStorage.setItem('profit_balance', "0", (err) => {
            if (err) {
              reject(err);
            }
          });
        });
        await new Promise((resolve, reject) => {
          window.Telegram.WebApp.CloudStorage.setItem('coin_balance', "5000", (err) => {
            if (err) {
              reject(err);
            }
          });
        });
        await new Promise((resolve, reject) => {
          window.Telegram.WebApp.CloudStorage.setItem('user_level', "1", (err) => {
            if (err) {
              reject(err);
            }
          });
        });

        

      } catch (error) {
        console.error('Registration Error:', error.response ? error.response.data : error.message);
        navigate('/tgbot/ServerErr');
      }
    };

    // Run the profit check and login/register process.
    const timer = setTimeout(async () => {
      await checkAndSetProfit();
      await loginAndRegisterUser();
    }, 1000);

    return () => clearTimeout(timer);
  }, [navigate, onScriptLoad]);

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(delayTimer);
  }, []);

  useEffect(() => {
    if (profitUser !== undefined) {
      console.log('Profit User updated:', profitUser);
    }
  }, [profitUser]);

  return (
    loading ? (
      <div className="loading-page">
        <img src={WelcomeImg} alt="SpaceFi" />
      </div>
    ) : (
      hasValue ? (
        <GiveProfit remainingSec={lastOffline} profit={profitUser} onScriptLoad={onScriptLoad} />
      ) : (
        <Home />
      )
    )
  );
};

export default LoadingPage;
