import { useContext, useEffect, useState } from "react";
import Astronout from "../images/Level/0 1.png";
import "../styles/components/LevelCounting.css";
import { CoinContext } from './CoinContext'; // Assuming you're using CoinContext to get the coin balance

const LevelCounting = () => {
    const { coinBalance } = useContext(CoinContext); // Get coinBalance from CoinContext
    const [level, setLevel] = useState(1);
    const [isLoading, setIsLoading] = useState(true); // To handle loading state

    // Level thresholds based on coin balance
    const LevelNeedMoney = {
        2: 1000000,
        3: 5000000,
        4: 25000000,
        5: 100000000,
        6: 500000000,
        7: 1000000000,
    };

    // Function to determine the current level based on coin balance
    const calculateLevel = (balance) => {
        let newLevel = 1;
        if (balance && !isNaN(balance)) {
            for (const [level, threshold] of Object.entries(LevelNeedMoney)) {
                if (balance >= threshold) {
                    newLevel = parseInt(level, 10);
                }
            }
        } else {
            console.error("Invalid coin balance:", balance);
        }
        console.log("Calculated Level:", newLevel);
        return newLevel;
    };

    // Fetch current level from Cloud Storage
    const fetchCurrentLevel = () => {
        return new Promise((resolve, reject) => {
            window.Telegram.WebApp.CloudStorage.getItem('user_level', (err, value) => {
                if (err) {
                    console.error("Error fetching current level:", err);
                    reject(err);
                } else {
                    const level = value !== null ? parseInt(value, 10) : 1; // Default to level 1 if value is null
                    resolve(isNaN(level) ? 1 : level); // Ensure level is a valid number
                }
            });
        });
    };

    // Update level in Cloud Storage
    const updateLevelInStorage = (newLevel) => {
        return new Promise((resolve, reject) => {
            window.Telegram.WebApp.CloudStorage.setItem('user_level', String(newLevel), (err) => {
                if (err) {
                    console.error("Error updating level:", err);
                    reject(err);
                } else {
                    resolve();
                }
            });
        });
    };

    useEffect(() => {
        const updateUserLevel = async () => {
            try {
                setIsLoading(true); // Start loading
                const currentLevel = await fetchCurrentLevel(); // Fetch current level
                console.log("Fetched Current Level:", currentLevel);
                const numericCoinBalance = Number(coinBalance); // Ensure coinBalance is a number
                const newLevel = calculateLevel(numericCoinBalance); // Calculate new level

                if (newLevel > currentLevel) {
                    await updateLevelInStorage(newLevel); // Update level in cloud storage if increased
                    setLevel(newLevel); // Update state with new level
                } else {
                    setLevel(currentLevel); // Ensure state reflects the current level
                }
            } catch (error) {
                console.error("Error updating user level:", error);
                setLevel("Error"); // Set level to "Error" in case of an issue
            } finally {
                setIsLoading(false); // Stop loading
            }
        };

        if (coinBalance !== undefined) {
            const numericCoinBalance = Number(coinBalance); // Ensure coinBalance is a number
            if (!isNaN(numericCoinBalance)) {
                updateUserLevel(); // Fetch and update user level when coin balance changes
            } else {
                console.error("Invalid coinBalance:", coinBalance);
                setLevel("Error"); // Set level to "Error" if coinBalance is invalid
            }
        }
    }, [coinBalance]); // Re-run this effect if coinBalance changes

    if (isLoading) {
        return <div>Loading...</div>; // Show loading indicator while fetching
    }

    return (
        <div className="level">
            <img src={Astronout} alt="SpaceFi" />
            <span>Level {level}</span> {/* Display the level */}
        </div>
    );
};

export default LevelCounting;
