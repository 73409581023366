import "../styles/components/NavbarMine.css"
import BackgroundActive from "../images/NavbarMine/Mine Selected.png"
import gold from "../images/NavbarMine/Pickaxe 2.png"
import { Link } from "react-router-dom"
const NavbarMine = () => {
    return (
        <nav>
            <Link to="/tgbot/">EARN</Link>
            <div>
                <img src={gold} className="goldImgNav" alt="coin" />
                <img className="bgImageNav ImageInMine" src={BackgroundActive} alt="bg" />
                <span>Mine</span>
            </div>

            <Link to="/tgbot/Stars">STARS</Link>
        </nav>
    )
}
export default NavbarMine