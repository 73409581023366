import { Link } from "react-router-dom"
import BackBtn from "../images/global/Polygon 1.png"
import "../styles/components/BackToHome.css"
const BackToHomeAsli = () => {
    return (

        <div className="BtnBack">
            <Link to="/tgbot">
                <img src={BackBtn} alt="Home" />
            </Link>
        </div>


    )
}
export default BackToHomeAsli 