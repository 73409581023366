import Task from "../images/taskboost/Gift Button 1.png"
import Boost from "../images/taskboost/Boost 2.png"
import { Link } from "react-router-dom"
import "../styles/components/FriendsWallet.css"
const TaskBoost =() => {
    return(
        <div className="FriednsWallet">
            <Link to="/tgbot/Tasks">
                <img src={Task} alt="Tasks"/>
                <span>Tasks</span>
            </Link>
            <Link to="/tgbot/Boost">
                <img src={Boost} alt="Boost"/>
                <span>Boost</span>
            </Link>
        </div>
    )
}
export default TaskBoost;