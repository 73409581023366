import brokenHeart from "../images/StarsSelect/Broken Heart 1.png";
import happy from "../images/StarsSelect/Happy 1.png";
import sad from "../images/StarsSelect/Sad 1.png";
import sheypour from "../images/StarsSelect/1f389 1.png";
import "../styles/components/StarsResponse.css";
import { useNavigate } from "react-router-dom";

const StarsResponse = ({ isRight, setVisible }) => {
    const navigate = useNavigate();

    const WellDoneClick = () => {
        navigate("/tgbot");
    };

    const BadClick = () => {
        setVisible(false);
    };

    return (
        <div className="MainDivSelectStarsResponse">
            {isRight ? (
                <div className="WrongSelectStarContainer">
                    <img src={happy} alt="spaceFi" className="sadAstro happy" />
                    <div className="textPartSelectStar">
                        <span className="oopsSelectStar">Yoohoo</span>
                        <div className="Text2SelectStar">
                            <span>Well done captain</span>
                            <img src={sheypour} alt="SpaceFi" />
                        </div>
                        <div className="ButtontryAgainSelectStar goodJobStar" onClick={WellDoneClick}>
                            Claim 5M
                        </div>
                    </div>
                </div>
            ) : (
                <div className="WrongSelectStarContainer">
                    <img src={sad} alt="spaceFi" className="sadAstro" />
                    <div className="textPartSelectStar">
                        <span className="oopsSelectStar">Oops !!!</span>
                        <div className="Text2SelectStar">
                            <span>Wrong Stars Captain</span>
                            <img src={brokenHeart} alt="SpaceFi" />
                        </div>
                        <div className="ButtontryAgainSelectStar" onClick={BadClick}>
                            Try Again
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StarsResponse;
