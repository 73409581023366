import BackToHomeAsli from "../components/BackToHomeAsli"
import BoostItems from "../components/BoostItems"
import { CoinProvider } from "../components/CoinContext"



const Boost = () => {
    return (
        <CoinProvider>
            <div>
                <BackToHomeAsli />
                <BoostItems />
            </div>
        </CoinProvider>
    )
}
export default Boost