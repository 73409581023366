import BackToHomeAsli from "../components/BackToHomeAsli"
import InviteBtn from "../components/InviteBtn"
import ShowFrens from "../components/ShowFrens"
const Friends = () => {
    return(
        <div>
            <BackToHomeAsli />
            <InviteBtn />
            <ShowFrens />
        </div>
    )              
}
export default Friends